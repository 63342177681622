







import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import CamiloDetail from "@/components/CamiloDetail.vue";
import { StoreProductDetail } from "@/api/cap-store.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

@Component({
  components: {
    CamiloDetail,
  },
})
export default class DetailContent extends Vue {
  @Prop() private readonly item!: StoreProductDetail;
  globalState = getModule(GlobalStateStore);
  get audit_version() {
    return this.globalState.audit_version;
  }
}
