


































import GlobalStateStore from "@/store/modules/global-state.store";
import { countdown } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

@Component({})
export default class GoodButtonOneUnique extends Vue {
  @Prop({ default: false }) private readonly home!: boolean | string;
  @Prop({ default: "red" }) private readonly theme!: "red" | "dark" ;
  @Prop({ default: false }) private readonly disabled!: boolean;
  @Prop({ default: false }) private readonly promise!: boolean;
  @Prop({ default: false }) private readonly member!: boolean;
  @Prop({ default: "" }) private readonly tag!: string;
  @Prop({ default: 0 }) private readonly tag_time!: number;
  @Prop() isVip!:boolean
  @Prop() presell!:boolean
  @Prop() isNot!:boolean
  @Prop({ default: false}) hidden!:boolean
  @Prop({ default: false}) isVipMember!:boolean
  @Prop({ default: false}) hasVip!:boolean
  @Prop() pay_type!:number
  @Prop() point!:number;
  @Prop() gold_price!:number;
  @Prop() repertory!:number;
  @Prop() status!:boolean;

  countdown = "00:00:00";
  timer: number = 0;
  globalState = getModule(GlobalStateStore);

  destroy() {
    clearInterval(this.timer);
  }
  memberFlag = false
  notPoint = false
  countdownpresell:any = ''

  destroyed(){
  }
  created(){
    const { reward_point = 0 } = this.globalState.userInfo || {};
      this.notPoint = reward_point < this.point;
    if(this.isVip&&!this.isNot&&this.pay_type!=0){
      this.memberFlag = true
    }
    else if(!this.isVip&&this.isVipMember&&this.hasVip&&this.pay_type != 0){
      this.memberFlag = true
    }
    if(this.repertory == 0||!this.status) {
      this.memberFlag = false
    }
  }
  mounted() {
    this.timer = setInterval(() => {
      if (!this.tag_time) return;
      const { day, hour, minute, second } = countdown(this.tag_time);
      if(this.presell){
        this.countdownpresell = `<span>${
        Number(day) ? `${day}天 ` : ""
      }</span><span class="presellcount" style="margin-left:2px">${hour}</span><span class="dotcount">:</span><span class="presellcount">${minute}</span><span class="dotcount">:</span><span class="presellcount">${second}</span>`;
      }else{
        this.countdown = `<span>${
        Number(day) ? `${day}天 ` : ""
      }</span><span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>`;
      }
    }, 1000);
  }

  get isHome() {
    return this.home !== false;
  }
  get IsAppState(){
    return InteractionLib.isApp()
  }
  get isDisabled() {
    if(this.repertory == 0)return true
    return this.disabled !== false;
  }

  @Emit()
  homeClick() {
    return;
  }

  @Emit()
  btnClick() {
    return;
  }
}
