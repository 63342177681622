
































import { countdown } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class GoodButtonOneVip extends Vue {
  @Prop({ default: false }) private readonly home!: boolean | string;
  @Prop({ default: "red" }) private readonly theme!: "red" | "dark" | "blue";
  @Prop({ default: false }) private readonly disabled!: boolean;
  @Prop({ default: false }) private readonly promise!: boolean;
  @Prop({ default: false }) private readonly member!: boolean;
  @Prop({ default: "" }) private readonly tag!: string;
  @Prop({ default: 0 }) private readonly tag_time!: number;
  @Prop() isVip!:boolean
  @Prop() isNot!:boolean
  @Prop() presell!:boolean

  @Prop({ default: false}) hidden!:boolean
  @Prop({ default: false}) isVipMember!:boolean
   @Prop({ default: false}) hasVip!:boolean
  countdown = "00:00:00";
  timer: number = 0;
  countdownpresell = "00:00:00";
  destroy() {
    clearInterval(this.timer);
  }
  memberFlag = false
  created(){
    if(this.isVip&&!this.isNot){
      this.memberFlag = true
    }
    else if(!this.isVip&&this.isVipMember&&this.hasVip){
      this.memberFlag = true
    }
  }
  mounted() {
    this.timer = setInterval(() => {
      if (!this.tag_time) return;
      const { day, hour, minute, second } = countdown(this.tag_time);
      if(this.presell){
        this.countdownpresell = `<span>${
        Number(day) ? `${day}天 ` : ""
      }</span><span class="presellcount" style="margin-left:2px">${hour}</span><span class="dotcount">:</span><span class="presellcount">${minute}</span><span class="dotcount">:</span><span class="presellcount">${second}</span>`;
      }else{
        this.countdown = `<span>${
        Number(day) ? `${day}天 ` : ""
      }</span><span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>`;
      }
      
    }, 1000);
  }
  destroyed(){}
  get isHome() {
    return this.home !== false;
  }
  get IsAppState(){
    return InteractionLib.isApp()
  }
  get isDisabled() {
    return this.disabled !== false;
  }

  @Emit()
  homeClick() {
    return;
  }

  @Emit()
  btnClick() {
    return;
  }
}
