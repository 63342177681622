















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { IBlindBoxItem } from "@/api/blind-box.api";
import EmptyBox from "@/components/EmptyBox.vue"
import SearchApi from "@/api/bazaar.api";
import BlindBoxItem from "@/views/welfare/components/BlindBoxItem.vue";
@Component({
  components: {
    EmptyBox,
    BlindBoxItem
  },
})
export default class WelfareBox extends Vue {
  blindBox: any = [];
  @Prop() id!:number
  async created(){
    this.blindBox = await SearchApi.getProductWelfare(String(this.id))
  }
}
