
























































































































































































































































































































































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { Tabs, Tab, Dialog, Skeleton, Toast, Popup } from "vant";
import GoodButton from "./product-detail/components/GoodButton.vue";
import GoodButtonOne from "./product-detail/components/GoodButtonOne.vue";
import GoodButtonOneSpc from "./product-detail/components/GoodButtonOneSpc.vue";
import GoodButtonOnePre from "./product-detail/components/GoodButtonOnePre.vue";

import GoodButtonOneVip from "./product-detail/components/GoodButtonOneVip.vue";
import GoodButtonVipPage from "./product-detail/components/GoodButtonVipPage.vue";
import GoodButtonOneUnique from "./product-detail/components/GoodButtonOneUnique.vue";
import WechatSub from "@/components/WechatSubscribeShipping.vue";

import PriceProfile from "./product-detail/components/PriceProfile.vue";
import CoverImage from "./product-detail/components/CoverImage.vue";
import { getModule } from "vuex-module-decorators";
import { Coupon, MineApi } from "@/api/mine.api";
import CapStoreStateStore from "@/store/modules/cap-store-state.store";
import GlobalStateStore from "@/store/modules/global-state.store";
import { throttle } from "lodash";
import CapStoreApi, {
  ProductPayType,
  StoreBuyProductResult,
  StoreProductDetail,
} from "@/api/cap-store.api";
import {
  countdown,
  getNow,
  monthday,
  observer,
  systemPay,
  wechatPay,
} from "@/utils/common.util";
import PayPop from "@/views/destiny-draw/components/destinyFate/PayPop.vue";
import ConfirmOut from "@/components/ConfirmOut.vue";
import { VanToast } from "vant/types/toast";
import WalletPaymentSelector from "@/components/WalletPaymentSelector.vue";
import InteractionLib from "@/utils/interaction.lib";
import { WechatApi } from "@/api/wechat.api";
import WechatSubscribe from "@/components/WechatSubscribe.vue";
import DetailContent from "@/views/store/product-detail/DetailContent.vue";
import MarketDetail from "@/views/store/product-detail/MarketDetail.vue";
import WelfareBox from "@/views/store/product-detail/WelfareBox.vue";
import SearchApi from "@/api/bazaar.api";
import MemberApi from "@/api/member.api";
import MemberBuyPop from "@/components/MemberBuyPop.vue";
import { LOGOUT_NAME } from "@/constant";
@Component({
  components: {
    WalletPaymentSelector,
    GoodButton,
    PriceProfile,
    CoverImage,
    VanSkeleton: Skeleton,
    PayPop,
    ConfirmOut,
    Tabs,
    Tab,
    GoodButtonOne,
    DetailContent,
    MarketDetail,
    WelfareBox,
    Popup,
    WechatSub,
    WechatSubscribe,
    GoodButtonOneVip,
    GoodButtonVipPage,
    GoodButtonOneUnique,
    GoodButtonOnePre,
    GoodButtonOneSpc,
    MemberBuyPop,
  },
})
export default class StoreDetail extends Vue {
  @Ref("WalletPaymentSelector")
  private readonly paymentSelectorRef!: WalletPaymentSelector;
  @Ref("MemberBuyPop")
  private readonly MemberBuyPopRef!: MemberBuyPop;
  wechat = InteractionLib.mobile.wechat;
  globalState = getModule(GlobalStateStore);
  capStoreState = getModule(CapStoreStateStore);
  product_id = Number(this.$route.params.id);
  coupon: Coupon | null = null;
  throttleImmediatelyChange = throttle(this.immediatelyChange.bind(this), 1500);
  openMemberPop = throttle(this.immediatelyOpen.bind(this), 1500);
  show = false;
  styleState = true;
  popState = false;
  radio = "1";
  fakeMoney = 0;
  number = 0;
  showSubScr = false;
  balance_money = 0;
  timertime = 0;
  disabled = false;
  active = 0;
  wideHigh: any = 0;
  wideHeight = "";
  isMemberPage = false;
  timeDesc = "";
  showPopTips = false;
  contentWidth: { height: string } | undefined;
  MemberPageData: any;
  orderSub: any;
  soldOut:any;
  openMemberPage() {
    this.MemberBuyPopRef.hidden();
    this.isMemberPage = true;
    this.openMemberPaypop();
  }
  get audit_version() {
    return this.globalState.audit_version;
  }
  async showPopTipsHandler() {
    if (this.product.force_tip) {
      this.showPopTips = true;
    }
  }

  async confirmSub() {
    this.showSubScr = false;
    if (InteractionLib.isFlutter()) {
      if (this.product.presell) {
        return InteractionLib.openWebView(
          `${location.origin}/my-order/${this.orderSub.order_no}`
        );
      }
      return InteractionLib.openWebView(
        `${location.origin}/store/${this.product_id}/result?order_no=${this.orderSub.order_no}`
      );
    }
    if (this.product.presell) {
      return this.$router.push({
        name: "MyOrderDetail",
        params: { order_no: this.orderSub.order_no },
      });
    }
    await this.$router.push({
      name: "StoreResult",
      params: { id: String(this.product_id) },
      query: { order_no: this.orderSub.order_no },
    });
  }
  hidePopTips() {
    this.showPopTips = false;
  }
  get bgColorChange() {
    if (this.product.home_sort < 0) {
      return {
        background: "linear-gradient(90deg, #ffebb3 0%, #ffcb66 100%)",
        color: "#933b00",
      };
    }
    return {
      background: "#FF3648",
      opcity: "0.95",
      color: "white",
    };
  }
  get timeFlag() {
    if (this.timeDesc) return false;
    return true;
  }
  combine(e: any) {
    this.styleState = e;
  }
  close() {
    this.styleState = true;
    this.show = false;
  }
  test() {
    this.styleState = true;
  }
  cheep(e: number) {
    this.fakeMoney = e;
  }
  checkRadio(radio: string) {
    this.radio = radio;
  }
  out() {
    this.ConfirmOutPopState = false;
  }

  get isLimitedTimeSale() {
    const { sale_price_expire_time } = this.product;
    if (sale_price_expire_time === 0) return this.product.home_sort < 0;
    if (sale_price_expire_time < getNow()) return false;
    return this.product.home_sort < 0;
  }
  confirm() {
    this.PayHandler(this.PayOrder!, this.PayToast!, this.pay_channel!);
    this.ConfirmOutPopState = false;
  }
  get buyGrayStyle() {
    if (this.product.status != true || this.product.repertory == 0) {
      return {
        background: `#F2F2F2!important`,
        color: `#969696!important`,
      };
    }
    return {};
  }
  get IsAppState() {
    return InteractionLib.isApp();
  }
  get ShopBuy() {
    if (this.product.sale_price == 0) {
      return `¥${this.product.price / 100}`;
    }
    if (!this.coupon) return `￥${this.product.sale_price / 100}`;
    return `￥${this.coupon.price / 100}`;
  }
  checkMoreFare() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/supply-store`);
    }
    this.$router.push({ path: "/welfare" });
  }
  showPop(e: any) {
    this.show = false;
    this.popState = true;
  }
  async userLogin() {
    if (!this.wechat) {
      InteractionLib.login();
      return;
    }
    location.href = await WechatApi.getAuthUri();
  }
  get isLogin(): boolean {
    return Boolean(this.globalState.userToken);
  }
  tohome() {
    if (InteractionLib.isFlutter()) {
      return InteractionLib.router(18, { tab: 2 });
    }
    this.$router.push({ path: "/" });
  }
  async payImmediatelyChange(
    pay_channel: number,
    deduction_wallet?: boolean
  ): Promise<void> {
    const result = await this.capStoreState.buyProduct({
      id: this.product.id,
      channel: pay_channel,
      deduction_wallet,
      openid: pay_channel === 3 ? this.globalState.openId || "" : "",
    });

    //微信支付 channel = 3 蒸汽币 = 4
    if (!result) return;

    if (pay_channel == 4) {
      Toast.clear();
      if (this.product.presell && !this.IsAppState) {
        this.showSubScr = true;
        this.orderSub = null;
        this.orderSub = result;
        return;
      }
      if (InteractionLib.isFlutter()) {
        if (this.product.presell) {
          return InteractionLib.openWebView(
            `${location.origin}/my-order/${result.order_no}`
          );
        }
        return InteractionLib.openWebView(
          `${location.origin}/store/${this.product_id}/result?order_no=${result.order_no}`
        );
      }
      if (this.product.presell) {
        this.$router.push({
          name: "MyOrderDetail",
          params: { order_no: result.order_no },
        });
        return;
      }
      this.$router.push({
        name: "StoreResult",
        params: { id: String(this.product_id) },
        query: { order_no: result.order_no },
      });
      return;
    }
    const toast = Toast.loading({
      message: "支付中...",
      duration: 0,
      mask: true,
      forbidClick: true,
    });
    this.PayHandler(result, toast, pay_channel);
  }
  ConfirmOutPopState = false;
  PayOrder: StoreBuyProductResult | undefined = undefined;
  PayToast: VanToast | undefined = undefined;
  pay_channel: number | undefined = undefined;
  async PayHandler(
    order: StoreBuyProductResult,
    toast: VanToast,
    pay_channel: number
  ) {
    this.PayOrder = order;
    this.PayToast = toast;
    this.pay_channel = pay_channel;
    toast.clear();
    const result = await systemPay(pay_channel, order);

    if (!result) {
      const orderStatus = await CapStoreApi.getProductBuyRecord(
        this.product_id,
        order.order_no
      ).catch(() => undefined);
      const exchangeStatus = () => {
        const index = orderStatus?.status.index || -1;
        if ([0, 1].includes(index)) {
          return 0;
        }
        if (index === 2) {
          return 1;
        }
        if ([3, 4].includes(index)) {
          return 2;
        }
        return 3;
      };
      if (!orderStatus && exchangeStatus() !== 1) {
        document.documentElement.style.overflowY = "hidden";
        this.ConfirmOutPopState = true;
        this.updateCoupon();
        Toast("支付取消");
        return;
      }
    }
    if (this.product.presell && !this.IsAppState) {
      this.orderSub = null;
      this.showSubScr = true;
      this.orderSub = order;
      return;
    }
    // if (!result && [1, 2].includes(pay_channel)) return;
    if (InteractionLib.isFlutter()) {
      if (this.product.presell) {
        return InteractionLib.openWebView(
          `${location.origin}/my-order/${order.order_no}`
        );
      }
      return InteractionLib.openWebView(
        `${location.origin}/store/${this.product_id}/result?order_no=${order.order_no}`
      );
    }
    if (this.product.presell) {
      return this.$router.push({
        name: "MyOrderDetail",
        params: { order_no: order.order_no },
      });
    }
    await this.$router.push({
      name: "StoreResult",
      params: { id: String(this.product_id) },
      query: { order_no: order.order_no },
    });
  }
  openPaypop() {
    this.showPopTipsHandler();
    this.paymentSelectorRef.show(
      this.price,
      async (channel, deduction_wallet) => {
        this.payImmediatelyChange(channel, deduction_wallet);
      }
    );
  }
  get is_price() {
    return this.product.pay_type === ProductPayType.money;
  }

  get versionCode() {
    return this.globalState.versionCode;
  }

  async immediatelyChange() {
    this.disabled = true;
    this.isMemberPage = false;
    if (this.product.type !== 1) {
      await (!this.is_price
        ? this.pointImmediatelyChange()
        : this.openPaypop());
    } else {
      // 下订单页面

      if (InteractionLib.isFlutter()) {
        return InteractionLib.openWebView(
          `${location.origin}/store/${this.product_id}/order`
        );
      }
      return this.$router.push({
        name: "StoreOrder",
        params: { id: String(this.product_id) },
        query: { time: String(getNow()) },
      });
    }
    this.disabled = false;
  }
  async immediatelyOpen() {
    this.MemberBuyPopRef.show();
  }
  openMemberPaypop() {
    this.paymentSelectorRef.show(99, (channel, deduction_wallet) => {
      this.payImmediatelyOpen(channel, deduction_wallet);
      this.isMemberPage = false;
    });
  }
  async payImmediatelyOpen(
    pay_channel: number,
    deduction_wallet?: boolean
  ): Promise<void> {
    const result = await this.capStoreState.buyProduct({
      id: this.MemberPageData.gold_vip_product_id,
      channel: pay_channel,
      deduction_wallet,
      openid: pay_channel === 3 ? this.globalState.openId || "" : "",
    });
    if (!result) return;
    if (pay_channel == 4) {
      Toast.clear();
      if (InteractionLib.isFlutter()) {
        return InteractionLib.openWebView(
          `${location.origin}/store/${this.MemberPageData.gold_vip_product_id}/result?order_no=${result.order_no}`
        );
      }
      await this.$router.push({
        name: "StoreResult",
        params: { id: String(this.MemberPageData.gold_vip_product_id) },
        query: { order_no: result.order_no },
      });
      return;
    }
    const toast = Toast.loading({
      message: "支付中...",
      duration: 0,
      mask: true,
    });
    this.PayHandlerPop(result, toast, pay_channel);
  }
  async PayHandlerPop(
    order: StoreBuyProductResult,
    toast: VanToast,
    pay_channel: number
  ) {
    this.PayOrder = order;
    this.PayToast = toast;
    this.pay_channel = pay_channel;
    const result = await systemPay(pay_channel, order);
    toast.clear();
    if (!result) {
      document.documentElement.style.overflowY = "hidden";
      this.ConfirmOutPopState = true;
      this.updateCoupon();
      Toast("支付取消");
    }
    if (InteractionLib.isFlutter()) {
      InteractionLib.goldVipStatusNotice();
    }
    this.CheckMemberIs();
  }
  async pointImmediatelyChange() {
    const result = await this.capStoreState.buyProduct({ id: this.product.id });
    if (typeof result === "object") {
      if (InteractionLib.isFlutter()) {
        return InteractionLib.openWebView(
          `${location.origin}/store/${this.product_id}/result?order_no=${result.order_no}`
        );
      }
      await this.$router.push({
        name: "StoreResult",
        params: { id: this.product_id.toString() },
        query: { order_no: result.order_no },
      });
    }
  }

  get price() {
    if (
      this.product.gold_price &&
      this.product.pay_type != 0 &&
      this.isVipMember
    ) {
      return this.product.gold_price / 100;
    }
    if (this.coupon) {
      return this.coupon.price;
    }
    if (this.product.sale_price) {
      return this.product.sale_price / 100;
    }
    return this.product.price / 100;
  }
  get btnText() {
    const { repertory, status, price, sale_price, pay_type, point } =
      this.product;
    if (this.product.gold_unique && !this.isVipMember) {
      if (!status) {
        return {
          disabled: true,
          theme: "dark",
          text: "已下架",
          tag: "",
          tag_time: 0,
        };
      }
      if (!repertory) {
        if(this.soldOut){
          return {
          disabled: true,
          theme: "dark",
          text: "已下架",
          tag: "",
          tag_time: 0,
          }
        }
        else{
        return {
          disabled: true,
          theme: "dark",
          text: "已售罄",
          tag: "",
          tag_time: 0,
        };
      }
      }
      return {
        text: "开通金会员 立即购买",
      };
    } else if (this.product.gold_unique && this.isVipMember) {
      if (!status) {
        return {
          disabled: true,
          theme: "dark",
          text: "已下架",
          tag: "",
          tag_time: 0,
        };
      }
      if (!repertory) {
        if(this.soldOut){
          return {
          disabled: true,
          theme: "dark",
          text: "已下架",
          tag: "",
          tag_time: 0,
          }
        }
        else{
        return {
          disabled: true,
          theme: "dark",
          text: "已售罄",
          tag: "",
          tag_time: 0,
        };
      }
      }
    }
    if (!this.product) {
      return {
        disabled: true,
        theme: "red",
        text: "加载中...",
        tag: "",
        tag_time: 0,
      };
    }

    if (this.product.home_sort < 0) {
      if (!repertory) {
        if(this.soldOut){
          return {
          disabled: true,
          theme: "dark",
          text: "已下架",
          tag: "",
          tag_time: 0,
          }
        }
        else{
        return {
          disabled: true,
          theme: "dark",
          text: "已售罄",
          tag: "",
          tag_time: 0,
        };
      }
      }
      if (!status) {
        return {
          disabled: true,
          theme: "dark",
          text: "已下架",
          tag: "",
          tag_time: 0,
        };
      }
    }
    if (!repertory) {
      if(this.soldOut){
          return {
          disabled: true,
          theme: "dark",
          text: "已下架",
          tag: "",
          tag_time: 0,
          }
        }
        else{
        return {
          disabled: true,
          theme: "dark",
          text: "已售罄",
          tag: "",
          tag_time: 0,
        };
      }
    }
    if (!status) {
      return {
        disabled: true,
        theme: "dark",
        text: "已下架",
        tag: "",
        tag_time: 0,
      };
    }
    if (pay_type === 0) {
      const { reward_point = 0 } = this.globalState.userInfo || {};
      console.log(reward_point, point, "pointvv");
      const notPoint = reward_point < point;
      return {
        disabled: notPoint,
        theme: "dark",
        text: notPoint ? "瓶盖不足" : `支付${point}瓶盖兑换`,
        tag: "",
        tag_time: 0,
      };
    }
    if (this.coupon) {
      const { price: couponPrice, failure_time } = this.coupon;
      if (this.product.gold_price && this.product.pay_type != 0) {
        if (!this.product.gold_unique && !this.isVipMember) {
          if (this.product.presell) {
            return {
              disabled: false,
              theme: "blue",
              text: `￥${couponPrice} 预购`,
              tag: "专属价剩余",
              tag_time: failure_time,
            };
          }
          return {
            disabled: false,
            theme: "red",
            text: `￥${couponPrice} 购买`,
            tag: "专属价剩余",
            tag_time: failure_time,
          };
        } else {
          return {
            disabled: false,
            theme: "red",
            text: `￥${this.product.gold_price / 100} 购买`,
            tag: "",
            tag_time: failure_time,
          };
        }
      }
      if (this.product.presell) {
        return {
          disabled: false,
          theme: "blue",
          text: `￥${couponPrice} 预购`,
          tag: "专属价剩余",
          tag_time: failure_time,
        };
      }
      return {
        disabled: false,
        theme: "red",
        text: `￥${couponPrice} 购买`,
        tag: "专属价剩余",
        tag_time: failure_time,
      };
    }
    const pay = sale_price || price;
    if (
      this.product.gold_price &&
      this.product.pay_type != 0 &&
      this.isVipMember
    ) {
      if (this.product.presell) {
        return {
          disabled: false,
          theme: "blue",
          text: `￥${this.product.gold_price / 100} 预购`,
          tag: "",
          tag_time: 0,
        };
      }
      return {
        disabled: false,
        theme: "red",
        text: `￥${this.product.gold_price / 100} 购买`,
        tag: "",
        tag_time: 0,
      };
    }
    if (this.product.presell) {
      return {
        disabled: false,
        theme: "blue",
        text: `￥${pay / 100} 预购`,
        tag: "",
        tag_time: 0,
      };
    }
    return {
      disabled: false,
      theme: "red",
      text: `￥${pay / 100} 购买`,
      tag: "",
      tag_time: 0,
    };
  }
  activated() {}
  mounted() {
    observer("refreshInit", async () => {
      this.CheckMemberIs();
    });
    setInterval(() => {
      if (this.coupon && this.coupon?.failure_time) {
        const { day, hour, minute, second } = countdown(
          this.coupon.failure_time
        );
        const is_zero = (...time: (number | string)[]) =>
          time.map((row) => Number(row) === 0).filter(Boolean).length ===
          time.length;
        if (is_zero(day, hour, minute, second)) {
          this.updateCoupon();
          return;
        }
      }
      if (this.product?.start_time > getNow()) {
        const { day, hour, minute, second } = countdown(
          this.product?.start_time
        );
        this.timeDesc = `距开售还有 ${day}天${hour}:${minute}:${second}`;
      } else {
        if (this.timeDesc !== "") this.timeDesc = "";
      }
    }, 1000);
    CapStoreApi.recordVisitLog(this.product_id);
  }

  @Watch("product")
  private async watchDetail(product: StoreProductDetail | null) {
    if (!product || product.pay_type === ProductPayType.point) return;
    const result = await MineApi.getMyCoupons(0, product.id);
    if (!result.length) return;
    this.coupon = result.find(Boolean) || null;
  }
  toMarketPay() {
    let routerId = `${this.product.voucher_group}-${this.product.voucher_group}-0-0`;
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${
          location.origin
        }/marketpay/${`${this.product.voucher_group}-${this.product.voucher_group}-0-0`}?right=false`
      );
    }
    this.$router.push({
      name: "marketpay",
      params: {
        id: `${this.product.voucher_group}-${this.product.voucher_group}-0-0`,
      },
    });
  }
  rightnowPay() {
    if (this.saleCuppon.sale_min_price == 0) return;
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${
          location.origin
        }/marketpay/${`${this.product.voucher_group}-${this.product.voucher_group}-0-0`}?right=true`
      );
    }
    this.$router.push({
      name: "marketpay",
      params: {
        id: `${this.product.voucher_group}-${this.product.voucher_group}-0-0`,
      },
      query: {
        right: "true",
      },
    });
  }
  updateCoupon() {
    this.coupon = null;
    this.watchDetail(this.product);
  }
  isVipMember = false;
  get old_product_id() {
    return this.capStoreState.product_id;
  }

  get product(): StoreProductDetail {
    return this.capStoreState.product!;
  }
  saleCuppon: any = [];
  async CheckMemberIs() {
    const { status } = await MemberApi.checkMemberIs().catch(() => ({
      status: 0,
    }));

    if (status == 1) {
      this.isVipMember = true;
    }
  }

  async created() {
    this.CheckMemberIs();
    if(this.$route.query.show == 'true'){
      this.soldOut = true
    }

    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) - 24;
    this.wideHeight = (this.wideHigh + 24) / 3 + "px";
    if (!this.product || this.old_product_id !== this.product_id) {
      await this.init();
    }
    if (Number(this.$route.query.tab) == 1) {
      this.active = 1;
    }
    this.saleCuppon = await SearchApi.getProductDetail(
      `${this.product.voucher_group}-${this.product.voucher_group}-0-0`
    ).catch((err) => console.log(err));
    this.MemberPageData = await MemberApi.getMemberData();
    this.watchDetail(this.product);
  }

  async init() {
    await this.capStoreState
      .getProduct(this.product_id)
      .catch((err) => console.log(err));

    if (this.globalState.userToken && !this.globalState.userInfo) {
      this.globalState.getUserInfo();
    }

    const timer = setInterval(() => {
      if (this.product.start_time > getNow()) {
        const { day, hour, minute, second } = countdown(
          this.product.start_time
        );

        this.timeDesc = `距开售还有 ${day}天${hour}:${minute}:${second}`;
      } else {
        this.timeDesc = "";
        clearInterval(timer);
      }
    }, 1000);
  }
}
