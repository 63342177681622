







































import InteractionLib from "@/utils/interaction.lib";
import { Component, Emit, Vue } from "vue-property-decorator";

@Component({})
export default class WechatSubscribeShipping extends Vue {
  @Emit()
  click() {
    return;
  }

  get flutter() {
    return InteractionLib.isApp();
  }

  subError(e: any) {
    this.click();
  }

  success(e: any) {
    this.click();
  }

  handlerClick() {
    this.click();
  }
}
