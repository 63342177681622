





























import { countdown } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class GoodButton extends Vue {
  @Prop({ default: false }) private readonly home!: boolean | string;
  @Prop({ default: "red" }) private readonly theme!: "red" | "dark" ;
  @Prop({ default: false }) private readonly disabled!: boolean;
  @Prop({ default: false }) private readonly promise!: boolean;
  @Prop({ default: false }) private readonly member!: boolean;
  @Prop({ default: "" }) private readonly tag!: string;
  @Prop({ default: 0 }) private readonly tag_time!: number;
  @Prop() isVip!:boolean
  @Prop() isNot!:boolean
  countdown = "00:00:00";
  timer: number = 0;

  destroy() {
    clearInterval(this.timer);
  }
  memberFlag = false
  created(){
    if(this.isVip&&!this.isNot){
      this.memberFlag = true
    }
  }
  mounted() {
    this.timer = setInterval(() => {
      if (!this.tag_time) return;
      const { day, hour, minute, second } = countdown(this.tag_time);
      this.countdown = `<span>${
        Number(day) ? `${day}天 ` : ""
      }</span><span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>`;
    }, 1000);
  }

  get isHome() {
    return this.home !== false;
  }
  get IsAppState(){
    return InteractionLib.isApp()
  }
  get isDisabled() {
    return this.disabled !== false;
  }
  destroyed(){
  }
  @Emit()
  homeClick() {
    return;
  }

  @Emit()
  btnClick() {
    return;
  }
}
