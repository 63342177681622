






import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class CoverImage extends Vue {
  @Prop({ default: "" }) private readonly src!: string;
@Prop() private readonly wideHigh!:any;
  contentWidth: { height: string; }|undefined;
  get rootStyle() {
    const { src } = this;
    return {
      '--cover': `url(${src})`,
      // 'height': `${this.wideHigh/ 1.77}px`
    };
  }
  get imgStyle() {
    return {
      // width:`${this.wideHigh *0.45}px!important`,
      // height:`${this.wideHigh *0.45}px!important`
    }
  }
}
