










































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { StoreProductDetail } from "@/api/cap-store.api";
import CurrentInfo from "@/views/store/product-detail/components/CurrentInfo.vue";
import EmptyBox from "@/components/EmptyBox.vue";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";
import SearchApi from "@/api/bazaar.api";
@Component({
  components: {
    CurrentInfo,
    EmptyBox,
  },
})
export default class MarketDetail extends Vue {
  playerList: any = [];
  @Prop() id!: number;
  @Prop() items: any;
  // entrust_max_price: string = "";
  // sale_min_price: string = "";
  globalState = getModule(GlobalStateStore);
  get appName(){
    if(this.globalState.packageName == 'com.vgn.gamepower'){
      return '游戏动力'
    }
    return '超级蒸汽'
  }
  get entrust_max_price() {
    if(this.items.entrust_max_price == "0.00"){
      return "-"
    }
    return `${this.items.entrust_max_price}`
  }
get sale_min_price() {
    if(this.items.sale_min_price == "0.00"){
      return "-"
    }
    return `${this.items.sale_min_price}`
  }
  async created() {
    this.playerList = await SearchApi.getPlayerList(
      `${this.id}-${this.id}-0-0`
    );
  }
}
