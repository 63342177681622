



























































































































































import { ProductPayType } from "@/api/cap-store.api";
import { countdown, monthday } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import moment from "moment";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";
@Component({})
export default class BasePrice extends Vue {
  @Prop({ default: 0 }) private readonly price!: number | string;
  @Prop({ default: 0 }) private readonly sale_price!: number | string;
  @Prop({ default: 0 }) private readonly bazaar_price!: number | string;
  @Prop({ default: 0 }) private readonly limit_number!: number | string;
  @Prop({ default: 0 }) private readonly limit_day!: number | string;
  @Prop({ default: "" }) private readonly title!: number | string;
  @Prop({ default: "Steam官方价" }) private readonly bazaar_text!: string;
  @Prop() gold_price!: number;
  @Prop() gold_unique!: boolean;
  @Prop({ default: 0 }) private readonly repertory!: number;
  @Prop() isVipMember!: boolean;
  @Prop() home_sort!: number;
  @Prop() presell!: boolean;
  @Prop() finish_time!: number;
  @Prop({ default: ProductPayType.money })
  private readonly pay_type!: ProductPayType;
  @Prop({ default: 0 }) private readonly point!: number;
  @Prop() private readonly coupon!:any;
  get lineThrough() {
    return {
      "text-decoration": this.isSalePrice ? "line-through" : "",
    };
  }
  globalState = getModule(GlobalStateStore);

  get appName(){
    if(this.globalState.packageName == 'com.vgn.gamepower'){
      return '游戏动力商城'
    }
    return '超级蒸汽商城'
  }
  @Emit()
  toMemberPage() {}
  mounted() {
    document.documentElement.scrollTop = 0;
  }
  get isSalePrice() {
    const price = Number(this.sale_price);
    if (Number.isNaN(price)) return false;
    if (!price) return false;
    return price > 0;
  }
  get finishDate() {
    return moment(this.finish_time * 1000).format('预售商品（最晚将于YYYY年MM月DD日HH点前发货）');
  }
  get isBazaarText() {
    const price = Number(this.bazaar_price);
    if (Number.isNaN(price)) return false;
    if (!price) return false;
    return price > 0;
  }
  get rules() {
    if (this.limit_day == undefined || this.limit_number == undefined)
      return "";
    if (this.limit_day === -1) return "";
    if (this.limit_day === 0) return `每人限购${this.limit_number}件`;
    return `每人每${this.limit_day}天限购${this.limit_number}件`;
  }
}
