












































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import BasePrice from "./price-style/BasePrice.vue";
import SpecialPrice from "./price-style/SpecialPrice.vue";
import { Coupon } from "@/api/mine.api";
import CamiloDetail from "@/components/CamiloDetail.vue";
import { StoreProductDetail } from "@/api/cap-store.api";
import { getNow } from "@/utils/common.util";

@Component({
  components: { CamiloDetail, SpecialPrice, BasePrice },
})
export default class PriceProfile extends Vue {
  @Prop() private readonly item!: StoreProductDetail;
  @Prop({ default: undefined }) private readonly coupon!: Coupon;
  @Prop() timeFlag!: boolean;
  @Prop() isVipMember!: boolean;
  ontimeFlagChanged() {}
  get isLimitedTimeSale() {
    const { sale_price_expire_time } = this.item;
    if (sale_price_expire_time === 0) return this.item.home_sort < 0;
    if (sale_price_expire_time < getNow()) return false;
    return this.item.home_sort < 0;
  }
  @Emit()
  toMemberPage() {}
}
